import React from "react";
import dayjs from "dayjs";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";

import { CalendarProps, DateType } from "./types";

import "flatpickr/dist/themes/airbnb.css";
import "./styles.scss";

export const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";

const formatDate = (type: DateType, selectedDate: Date, format: string, dates: Date[]) => {
  if (!dates || dates.length === 0) return "";
  switch (type) {
    case "multiple":
    case "single": {
      const formattedDate = dayjs(selectedDate).format(format);
      return formattedDate;
    }
    case "range": {
      const initFormattedDate = dayjs(dates[0]).format(format);
      const endFormattedDate = dayjs(dates[1]).format(format);

      const formattedDate = dates[1]
        ? `${initFormattedDate} - ${endFormattedDate}`
        : initFormattedDate;

      return formattedDate;
    }
  }
};

export function Calendar(props: CalendarProps) {
  const {
    type = "single",
    showDateInput = false,
    dateFormat = DEFAULT_DATE_FORMAT,
    defaultDate,
    minDate,
    maxDate,
    customInput,
    onChangeDate,
    disableMobile = false,
  } = props;

  const [selectedDate, setSelectedDate] = React.useState<Date[]>(defaultDate);

  const onRenderInput = (
    props: Omit<DateTimePickerProps, "options" | "render">,
    ref: (node: HTMLInputElement) => void
  ) => {
    if (customInput && ref) {
      const renderedCustomInput = customInput({ innerRef: ref, ...props });
      return renderedCustomInput;
    } else {
      if (showDateInput) {
        return (
          <input
            ref={ref}
            className="flatpickr-input"
            placeholder="Select Date"
          />
        );
      } else {
        return (
          <input
            ref={ref}
            style={{ display: "none" }}
          />
        );
      }
    }
  };

  React.useEffect(() => {
    if (onChangeDate) onChangeDate(selectedDate);
  }, [selectedDate]);

  return (
    <Flatpickr
      className="calendar"
      data-testid="Calendar"
      onChange={setSelectedDate}
      value={selectedDate}
      render={(props, ref) => onRenderInput(props, ref)}
      options={{
        inline: customInput || showDateInput ? false : true,
        mode: type,
        dateFormat,
        minDate,
        maxDate,
        disableMobile,
        formatDate: (date, format) => {
          return formatDate(type, date, format, selectedDate);
        },
      }}
    />
  );
}
