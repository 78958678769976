import React from "react";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import { QRFRameProps } from "../types";

import "../styles.scss";

export const TicketFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="72"
      height="96"
      viewBox="0 0 72 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.9999 8H72V88H71.9999C67.5816 88 63.9999 91.5817 63.9999 96H7.99992C7.99992 91.5817 4.41824 88 0 88V8C4.41824 7.99995 7.99992 4.41825 7.99992 0H63.9999C63.9999 4.41828 67.5816 8 71.9999 8ZM67.2003 21.6H4.80029V84H67.2003V21.6Z"
        fill={frameColor}
      />
      <foreignObject
        width="100%"
        height="22px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="12"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Nunito Sans"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>

      <foreignObject
        width="86%"
        height="63px"
        x="5"
        y="21">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "2px",
            backgroundColor: "white",
            paddingTop: "3px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
    </svg>
  );
};
