import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const BlackFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="80"
      height="98"
      viewBox="0 0 72 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V92C0 94.2091 1.79086 96 4 96H68C70.2091 96 72 94.2091 72 92V4C72 1.79086 70.2091 0 68 0H4ZM66.9 4.5H4.5V66.9H66.9V4.5Z"
        fill={frameColor}
      />
      <foreignObject
        width="88%"
        height="64px"
        x="4"
        y="4">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            WebkitBoxSizing: "border-box",
            paddingLeft: "2px",
            paddingTop: "2px",
            paddingRight: "2px",
            paddingBottom: "1px",
            backgroundColor: "white",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="72">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="10"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Nunito Sans"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
