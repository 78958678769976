"use client";
import React from "react";
import {
  isArray,
  includes,
  forEach,
  keys,
  findIndex,
  find,
  map,
  has,
  isObject,
  merge,
} from "lodash";
import { FieldValues, useFormContext, UseFormReturn, useWatch } from "react-hook-form";

import { FormFieldProps, FormValidationMethods } from "@/components/Form/types";

import { ConnectFormProps, FindFieldById } from "./types";

export const ConnectForm = ({ children, onMethods }: ConnectFormProps) => {
  const methods: FormValidationMethods = useFormContext();
  const childName = children?.props?.name;
  const rules = methods?.schema && find(methods.schema, { name: childName });

  if (methods) {
    useWatch({
      name: childName,
    });
    onMethods && onMethods(methods);
  }

  return methods && childName
    ? React.cloneElement(children, {
        ...methods.register(childName, {
          ...rules?.rules,
          onChange: () => {
            if (methods.formState.errors[childName]) {
              methods.clearErrors(childName);
            }
            if (methods.formState.isDirty && !methods.formState.errors[childName]) {
              methods.resetField(childName, {
                defaultValue: methods.getValues(childName),
              });
            }
            if (children?.props?.onChange) {
              children.props.onChange(methods.getValues(childName));
            }
          },
        }),
      })
    : children;
};

export const getRuleErrorMessage = (fieldName: string, ruleName: string, data: any) => {
  if (!data) return;

  for (const formField of data.formFields) {
    if (formField.name === fieldName) {
      return find(formField.errorMessage, { rule: ruleName })?.message;
    }

    const formFieldNested = formField.formFields
      ? getRuleErrorMessage(fieldName, ruleName, formField)
      : undefined;
    if (formFieldNested) {
      return formFieldNested;
    }
  }
};

export const getRuleErrorMessageFromNestedComponent = (
  fieldName: string,
  ruleName: string,
  data: any
) => {
  if (!data) return;
  if (isObject(data) as any) {
    if (data.name === fieldName) {
      const errorMessage = find(data.errorMessage, { rule: ruleName })?.message;
      return errorMessage || "";
    }

    // Recursively search for the fieldName in nested objects
    for (const key in data) {
      const errorMessage = getRuleErrorMessageFromNestedComponent(fieldName, ruleName, data[key]);
      if (errorMessage) return errorMessage;
    }
  }
};

export const isFieldFormLoading = (name?: string, isLoading?: boolean | string[]) => {
  if (!name) return false;

  if (!isLoading || (isArray(isLoading) && isLoading.length === 0)) return false;

  if (isArray(isLoading)) return includes(isLoading, name);
  return isLoading;
};

export const resetForm = (values: FieldValues, methods: UseFormReturn) => {
  forEach(keys(values), (fieldKey) => {
    methods.resetField(fieldKey);
    methods.setValue(fieldKey, undefined);
  });
};

export const findFieldById: FindFieldById = (data: any, id: string, isIndex?: boolean) => {
  return isIndex
    ? findIndex(data, {
        id: id,
      })
    : find(data, {
        id: id,
      });
};

export const onBindClick = (data: any, id: string, fn: any) => {
  if (!data) return;

  for (const formField of data) {
    if (formField.id === id) {
      formField.onClick = fn;
      return;
    }

    const formFieldNested = formField.formFields
      ? onBindClick(formField.formFields, id, fn)
      : undefined;
    if (formFieldNested) return formFieldNested;
  }
};

export const onUpdateFormFields = (
  data: FormFieldProps[],
  id: string,
  propertyKey: string,
  propertyValue: any
) => {
  if (!data) return;

  return data.map((formField: FormFieldProps) => {
    Object.keys(formField).forEach((key) => {
      if (key === "id" && formField.id === id) {
        formField[propertyKey] = propertyValue;
      }

      if (key === "formFields") {
        onUpdateFormFields(formField[key], id, propertyKey, propertyValue);
      }
    });

    return formField;
  });
};

/*export const applyQRFormDefaultValues = (data: any, localStorageData: any) => {
  return map(data.formFields, (formField) => {
    return {
      ...formField,
      defaultValue:
        (localStorageData && localStorageData[formField.name]) || formField.defaultValue,
      formFields: has(formField, "formFields")
        ? applyQRFormDefaultValues(formField, localStorageData)
        : undefined,
    };
  });
};*/

const getMergedDefaultValue = (data, field) => {
  if (!data && !data[field.name]) {
    return field.defaultValue;
  }

  if (field.extended) {
    const defaultData = data[field.name] || [];

    return defaultData.map((dataField) => {
      const fieldFromOptions = field.options.find((option) => option.id === dataField.id);
      return merge(dataField, fieldFromOptions);
    });
  }

  return data[field.name];
};
export const applyQRFormDefaultValues = (data: any, localStorageData: any) => {
  return map(data.formFields, (formField) => {
    return {
      ...formField,
      defaultValue: getMergedDefaultValue(localStorageData, formField),
      formFields: has(formField, "formFields")
        ? applyQRFormDefaultValues(formField, localStorageData)
        : undefined,
    };
  });
};

export const getFormFields = (formFields: FormFieldProps[], fieldIdList: string[]): any => {
  const filteredFormFields = [];
  forEach(fieldIdList, (fieldId) => {
    const foundField = find(formFields, { id: fieldId });
    if (foundField) filteredFormFields.push(foundField);
  });

  return { formFields: filteredFormFields };
};

export const URLRegex =
  "^(?:https?:\\/\\/(?:www\\.)?|https:(?:\\/\\/)?)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)";

export const checkValidURL = (buttonUrl: string) => {
  if (!buttonUrl) return undefined;

  return buttonUrl?.includes("http") ? buttonUrl : "https://" + buttonUrl;
};

export const formatClientUrl = (url: string) => {
  if (!url) return undefined;

  return url?.includes("http") ? url.split("://").pop() : url;
};
