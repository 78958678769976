import React from "react";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import { QRFRameProps } from "../types";

import "../styles.scss";

export const MobileFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="72"
      height="96"
      viewBox="0 0 72 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H66C69.3137 0 72 2.68629 72 6V90C72 93.3137 69.3137 96 66 96H6C2.68629 96 0 93.3137 0 90V6ZM36.0001 93.6C38.9824 93.6 41.4001 91.1823 41.4001 88.2C41.4001 85.2177 38.9824 82.8 36.0001 82.8C33.0177 82.8 30.6001 85.2177 30.6001 88.2C30.6001 91.1823 33.0177 93.6 36.0001 93.6ZM35.9997 92.1C38.1536 92.1 39.8997 90.3539 39.8997 88.2C39.8997 86.0461 38.1536 84.3 35.9997 84.3C33.8458 84.3 32.0997 86.0461 32.0997 88.2C32.0997 90.3539 33.8458 92.1 35.9997 92.1ZM67.1998 18H4.7998V80.4H67.1998V18Z"
        fill={frameColor}
      />
      <foreignObject
        width="100%"
        height="18px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="10"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Nunito Sans"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
      <foreignObject
        width="86%"
        height="64px"
        x="5"
        y="17">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "2px",
            paddingTop: "3px",
            backgroundColor: "white",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
    </svg>
  );
};
