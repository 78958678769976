import React from "react";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import { QRFRameProps } from "../types";

import "../styles.scss";

export const MuseumFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="72"
      height="96"
      viewBox="0 0 72 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 73.1429V0H72V73.1429H0ZM2.46963 1.52381H69.5304L66.7447 4.57143H5.25534L2.46963 1.52381ZM2.46963 71.619L5.25535 68.5714H66.7447L69.5304 71.619H2.46963ZM66 6.09524H6V67.0476H66V6.09524Z"
        fill={frameColor}
      />

      <foreignObject
        width="83%"
        height="60px"
        x="6"
        y="6">
        <div
          id="#qrCode"
          style={{
            border: `1px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            padding: "2px",
            backgroundColor: "white",
            paddingTop: "3px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="72">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="14"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Nunito Sans"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
