import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const EnvelopeFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="75"
      height="96"
      viewBox="0 0 75 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 95.5667V51.394C2 50.6683 2.32395 49.9804 2.88345 49.5181L35.8757 22.2594C36.7638 21.5257 38.0447 21.5152 38.9447 22.2344L73.0857 49.5175C73.6636 49.9792 74 50.6787 74 51.4184V95.5667C74 96.9106 72.9106 98 71.5667 98H4.43333C3.08944 98 2 96.9106 2 95.5667Z"
        fill="white"
        stroke="#404040"
        strokeWidth="2.43333"
      />
      <path
        d="M7.215 4C7.215 2.46189 8.46189 1.215 10 1.215H66C67.5381 1.215 68.785 2.46189 68.785 4V80C68.785 81.5381 67.5381 82.785 66 82.785H10C8.46189 82.785 7.215 81.5381 7.215 80V4Z"
        fill={frameBackgroundColor}
        stroke="#404040"
        strokeWidth="2.43"
      />
      <rect
        x="10"
        y="4"
        width="56"
        height="55"
      />
      <path
        d="M71.8348 96.0938L44.4898 75.5046C43.8177 74.9986 43.8497 73.9795 44.5523 73.5167L71.8973 55.5011C72.7063 54.9681 73.7833 55.5484 73.7833 56.5171V95.1219C73.7833 96.1255 72.6366 96.6975 71.8348 96.0938ZM4.16516 55.9062L31.5102 76.4954C32.1823 77.0014 32.1503 78.0205 31.4477 78.4834L4.1027 96.4989C3.29373 97.0319 2.21667 96.4517 2.21667 95.4829L2.21667 56.8781C2.21667 55.8745 3.36338 55.3025 4.16516 55.9062Z"
        stroke="#404040"
        fill={frameColor}
        strokeWidth="2.43333"
      />
      <path
        d="M2 95.5182V91.7332C2 90.9999 2.318 90.3041 2.86868 89.8325L35.8612 61.5811C36.7545 60.8162 38.0543 60.8053 38.9598 61.5552L73.1006 89.8315C73.6696 90.3028 74 91.0105 74 91.7581V95.5182C74 96.8889 72.9106 98 71.5667 98H4.43333C3.08944 98 2 96.8889 2 95.5182Z"
        stroke="#404040"
        fill={frameColor}
        strokeWidth="2.43333"
      />
      <foreignObject
        width="71%"
        height="54px"
        x="11"
        y="4">
        <div
          id="#qrCode"
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",

            backgroundColor: "white",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        y="80">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="10"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Nunito Sans"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
