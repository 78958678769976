import React from "react";

import { QRFRameProps } from "../types";

import { getPreviewFrameFontSize } from "@/utils/qrEditorHelper";

import "../styles.scss";

export const ReceiptFrame = (
  props: Omit<QRFRameProps, "frameType">
): React.SVGProps<SVGSVGElement> => {
  const { text, textColor, children, frameColor, frameBackgroundColor, urlQr } = props;

  const fontSize = getPreviewFrameFontSize(text?.length);

  return (
    <svg
      width="80"
      height="96"
      viewBox="0 0 80 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.81755 104.903L1.215 108.707V4C1.215 2.46189 2.46189 1.215 4 1.215H76C77.5381 1.215 78.785 2.46189 78.785 4V108.614L73.7147 104.926L72.9073 104.338L72.1746 105.017L67.5 109.344L62.8254 105.017L62.1379 104.38L61.3476 104.883L54.0727 109.514L48.2493 104.952L47.5 104.365L46.7507 104.952L41.0307 109.433L35.7862 104.982L35.0307 104.341L34.2507 104.952L28.4729 109.478L22.2147 104.926L21.5 104.406L20.7853 104.926L14.5 109.498L8.21471 104.926L7.5241 104.423L6.81755 104.903Z"
        fill={frameColor}
        stroke="#404040"
        strokeWidth="2.43"
      />
      <foreignObject
        width="83%"
        height="68px"
        x="7"
        y="6">
        <div
          id="#qrCode"
          style={{
            border: `4px solid ${frameBackgroundColor || "white"}`,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundColor: "white",
            paddingTop: "2px",
          }}>
          {urlQr ? (
            <img
              src={urlQr}
              width="100%"
              height="auto"
            />
          ) : (
            children
          )}
        </div>
      </foreignObject>
      <foreignObject
        width="100%"
        height="18px"
        x="-3"
        y="80">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%", maxWidth: "100%" }}>
          <text
            x="50%"
            y="10"
            fill={textColor}
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Nunito Sans"
            fontWeight="bold"
            style={{ fontSize }}>
            {text}
          </text>
        </svg>
      </foreignObject>
    </svg>
  );
};
