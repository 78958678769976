import React from "react";
import { createRoot } from "react-dom/client";
import { clone, find, flatten, isEmpty, map, startsWith } from "lodash";

import {
  ButtonFrameToDownload,
  MobileFrameToDownload,
  TicketFrameToDownload,
  MuseumFrameToDownload,
  WhiteFrameToDownload,
  BlackFrameToDownload,
  CafeFrameToDownload,
  ShoppingFrameToDownload,
  BoardFrameToDownload,
  EnvelopeFrameToDownload,
  ReceiptFrameToDownload,
} from "@/containers/MyQrCodeDetailsContainer/components/QRFrames/framesToPrint";

import {
  FrameType,
  QRFRameToPrintProps,
  QRFrameStyle,
} from "@/containers/MyQrCodeDetailsContainer/components/QRFrames/types";
import { QrCodeResponse } from "@/interface/api/getAllQrCodes/types";
import {
  TicketFrame,
  BlackFrame,
  ButtonFrame,
  MobileFrame,
  MuseumFrame,
  WhiteFrame,
  CafeFrame,
  ShoppingFrame,
  BoardFrame,
  EnvelopeFrame,
  ReceiptFrame,
} from "@/containers/MyQrCodeDetailsContainer/components/QRFrames/frames";

import { BusinessHoursDays } from "@/components/BusinessHours/types";
import { QRDownloadFormat, QrCodeProps } from "@/types/qr";

import { socials } from "@/internals/constants/socials";
import { QREditorContextProps, QRProps } from "@/contexts/QREditorContext/types";
import { INITIAL_QR_DATA, QREditorContext } from "@/contexts/QREditorContext";

import { QREditorAPItoDataConversor } from "@/services/ApiConversorService/QREditor";

import { isClient } from "./browser";

import { onBindClick } from "./formHelper";
import {
  themePickerCustomColors,
  themePickerInitialColors,
} from "@/components/ThemePicker/ThemePicker";

export const onBindSocialNetworks = (
  dataWithDefaultValues: any,
  componentKey: string,
  localStorageKey?: string,
  ommitedValues?: string[],
  links?: boolean,
  newData?: QRProps<"links" | "social">
) => {
  const qrEditorContext = React.useContext<QREditorContextProps<any>>(QREditorContext);

  onBindClick(dataWithDefaultValues, componentKey || "qrWebsiteSocials", (data: any[]) => {
    qrEditorContext?.setQrData(
      {
        ...qrEditorContext?.qrData,
        data: {
          ...qrEditorContext?.qrData.data,
          [localStorageKey]: [...data],
          ...(links && { links: newData?.data?.links }),
        },
      },
      ommitedValues || map(socials, (social) => `data.${social.id}`)
    );
  });
};

export const onBindThemePicker = (
  dataWithDefaultValues: any,
  componentKey: string,
  ommitedValues?: string[]
) => {
  const qrEditorContext = React.useContext<QREditorContextProps<any>>(QREditorContext);

  onBindClick(dataWithDefaultValues, componentKey || "qrwebsiteThemePicker", (colorKey: string) => {
    let themeColors = themePickerInitialColors;
    !isEmpty(themePickerCustomColors)
      ? (themeColors = themeColors.concat(themePickerCustomColors))
      : themeColors;

    const theme = find(themeColors, { key: colorKey });
    qrEditorContext?.setQrData(
      {
        ...qrEditorContext?.qrData,
        data: {
          ...qrEditorContext?.qrData.data,
          themeColor: {
            buttonColor: theme?.primary,
            backgroundColor: theme?.secondary,
          },
        },
      },
      ommitedValues
    );
  });
};

export const onBindImage = (
  dataWithDefaultValues: any,
  componentKey: string,
  ommitedValues?: string[]
) => {
  const qrEditorContext = React.useContext<QREditorContextProps<any>>(QREditorContext);
  const [imageBase64, setImageBase64] = React.useState("");

  onBindClick(
    dataWithDefaultValues,
    componentKey || "qrWebsiteInformationLogo",
    (imgBase64: string[]) => setImageBase64(imgBase64[0])
  );

  React.useEffect(() => {
    qrEditorContext?.setQrData(
      {
        ...qrEditorContext?.qrData,
        data: { ...qrEditorContext?.qrData.data, logoUrl: imageBase64 },
      },
      ommitedValues || ["data.logoUrl", "data.file"]
    );
  }, [imageBase64]);
};

export const getQRCodeUrl = (qrData: QRProps<any>, isPreview?: boolean) => {
  const isWifi = qrData.type === "wifi";
  const qrCode = qrData.qrCodeReference;
  const origin = isClient() ? window.location.origin : undefined;

  if (isWifi)
    return `WIFI:T:${qrData?.data?.qrNetworkSecurityType};S:${
      qrData?.data?.qrNetworkName
    };P:${qrData?.data?.qrNetworkPassword};H:${qrData?.data?.qrNetworkHidden || false}`;

  return origin && qrCode ? `${origin}${isPreview ? "/p/" : "/qr/"}${qrCode}` : origin;
};

export const isValidQrReference = (reference: string) => {
  const referenceRegex = new RegExp("^[0-9a-fA-F]{8}$");
  return referenceRegex.test(reference);
};

export const downloadQrAi = async (
  data: any,
  url: string,
  extension: QRDownloadFormat,
  nameOfFile: string
) => {
  const fileData = {
    type: extension === "png" ? "image/png" : extension === "jpeg" ? "image/jpeg" : "image/webp",
    name:
      extension === "png"
        ? `${nameOfFile}.png`
        : extension === "jpeg"
          ? `${nameOfFile}.jpg`
          : `${nameOfFile}.webp`,
  };

  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileData.name;

    link.click();

    window.URL.revokeObjectURL(link.href);
  } catch (err) {
    console.error("Error downloading image:", err);
  }
};

export const areWeInTheEditor = (): boolean =>
  isClient() && window.location.pathname.includes("/qr-editor");

export const getFramedQrToDownload = (
  frameStyle: FrameType,
  qrCodeSvg: string,
  data: QRFrameStyle
): React.SVGProps<SVGSVGElement> => {
  if (!frameStyle || frameStyle === "none") return;

  const sharedObject: QRFRameToPrintProps = {
    frameBackgroundColor: data.frameBackgroundColor,
    frameColor: data.frameColor,
    textColor: data.frameTextColor,
    text: data.frameText,
    qrSvg: qrCodeSvg,
    width: 900,
    height: 1200,
  };

  switch (frameStyle) {
    case "button":
      return ButtonFrameToDownload(sharedObject);
    case "mobile":
      return MobileFrameToDownload(sharedObject);
    case "ticket":
      return TicketFrameToDownload(sharedObject);
    case "museum":
      return MuseumFrameToDownload(sharedObject);
    case "white":
      return WhiteFrameToDownload(sharedObject);
    case "black":
      return BlackFrameToDownload(sharedObject);
    case "cafe":
      return CafeFrameToDownload(sharedObject);
    case "shopping":
      return ShoppingFrameToDownload(sharedObject);
    case "board":
      return BoardFrameToDownload(sharedObject);
    case "envelope":
      return EnvelopeFrameToDownload(sharedObject);
    case "receipt":
      return ReceiptFrameToDownload(sharedObject);
  }
};

export const getFramedQr = (
  frameStyle: FrameType | undefined,
  reference: React.MutableRefObject<any> | React.ReactElement | any,
  data: QrCodeResponse | any,
  urlQr?: string
): React.SVGProps<SVGSVGElement> | React.MutableRefObject<any> | React.ReactElement | any => {
  if (!frameStyle || frameStyle === "none") return reference;

  const sharedObject = {
    children:
      reference.type === "img" ? reference : <div ref={reference as React.MutableRefObject<any>} />,
    frameBackgroundColor: data.style.frameBackgroundColor,
    frameColor: data.style.frameColor,
    textColor: data.style.frameTextColor,
    text: data.style.frameText,
    urlQr,
  };

  switch (frameStyle) {
    case "ticket":
      return TicketFrame(sharedObject);
    case "black":
      return BlackFrame(sharedObject);
    case "button":
      return ButtonFrame(sharedObject);
    case "mobile":
      return MobileFrame(sharedObject);
    case "museum":
      return MuseumFrame(sharedObject);
    case "white":
      return WhiteFrame(sharedObject);
    case "cafe":
      return CafeFrame(sharedObject);
    case "shopping":
      return ShoppingFrame(sharedObject);
    case "board":
      return BoardFrame(sharedObject);
    case "envelope":
      return EnvelopeFrame(sharedObject);
    case "receipt":
      return ReceiptFrame(sharedObject);
  }
};

export const getPreviewFrameFontSize = (textLength?: number) => {
  let fontSize = "9px";

  if (textLength >= 12) {
    fontSize = "7px";
  }
  if (textLength >= 16) {
    fontSize = "5px";
  }
  if (textLength >= 23) {
    fontSize = "4px";
  }

  return fontSize;
};

export const getPreviewFramePrintFontSize = (textLength: number) => {
  let fontSize = "100px";

  if (textLength >= 12) {
    fontSize = "90px";
  }
  if (textLength >= 16) {
    fontSize = "60px";
  }
  if (textLength >= 23) {
    fontSize = "40px";
  }

  return fontSize;
};

const renderToString = async (element: React.ReactElement) => {
  const container = document.createElement("div");
  const root = createRoot(container);
  root.render(element);

  await new Promise((resolve) => setTimeout(resolve, 100));

  const html = container.innerHTML;
  root.unmount();

  return html;
};

export const downloadQrFramed = (
  qrCode: any,
  data: QRFrameStyle,
  extension: QRDownloadFormat,
  nameOfFile: string
) => {
  if (!qrCode) return;

  qrCode?.update({
    width: 100,
    height: 100,
  });

  qrCode?.getRawData("svg").then((blobData) => {
    const url = URL.createObjectURL(blobData);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();

        reader.onloadend = async () => {
          let svgText = reader.result as string;

          const startIndex = svgText.indexOf("<svg");
          const endIndex = svgText.lastIndexOf(">") + 1;
          const svgContent = svgText.substring(startIndex, endIndex);

          let positionVariables = "x='22' y='19' ";
          switch (data?.frameStyle) {
            case "ticket":
              positionVariables = "x='22' y='25' ";
              break;
            case "museum":
            case "black":
            case "white":
              positionVariables = "x='23' y='20' ";
              break;
          }

          const svgContentUpdated =
            svgContent.substring(0, 5) + positionVariables + svgContent.substring(5);

          const svgResult = getFramedQrToDownload(data?.frameStyle, svgContentUpdated, data) as any;
          const jsxElement = React.createElement(svgResult.type, svgResult.props);

          let svgString = await renderToString(jsxElement);

          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
          const imgElement = svgDoc.querySelector("image");

          if (imgElement) {
            let href = imgElement.getAttribute("href");
            if (href) {
              const response = await fetch(href);
              const blob = await response.blob();
              const imgReader = new FileReader();

              const base64Data = await new Promise((resolve) => {
                imgReader.onloadend = () => resolve(imgReader.result);
                imgReader.readAsDataURL(blob);
              });

              imgElement.setAttribute("href", base64Data as string);
            }
          }

          const serializer = new XMLSerializer();
          svgString = serializer.serializeToString(svgDoc.documentElement);

          if (extension === "png" || extension === "jpeg" || extension === "webp") {
            const fileData = {
              type:
                extension === "png"
                  ? "image/png"
                  : extension === "jpeg"
                    ? "image/jpeg"
                    : "image/webp",
              name:
                extension === "png"
                  ? `${nameOfFile}.png`
                  : extension === "jpeg"
                    ? `${nameOfFile}.jpg`
                    : `${nameOfFile}.webp`,
            };

            const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
            const imgUrl = URL.createObjectURL(svgBlob);

            const img = new Image();
            img.src = imgUrl;

            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.width;
              canvas.height = img.height;

              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);

              const link = document.createElement("a");
              link.href = canvas.toDataURL(fileData.type);
              link.download = fileData.name;
              link.click();

              URL.revokeObjectURL(imgUrl);
            };
          } else if (extension === "svg") {
            const blobb = new Blob([svgString], { type: "image/svg+xml" });
            const blobUrl = URL.createObjectURL(blobb);

            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = `${nameOfFile}.svg`;
            link.click();

            URL.revokeObjectURL(blobUrl);
          }
        };

        reader.readAsText(blob);
      })
      .catch((error) => {
        console.error("Error fetching SVG:", error);
      });
  });
};

export const buildQRWifiUrl = (qrData: QrCodeProps | QrCodeResponse) =>
  `WIFI:T:${qrData?.content?.["network-security-type"]};S:${
    qrData?.content?.["network-name"]
  };P:${qrData?.content?.["network-password"]};H:${
    qrData?.content?.["network-hidden-password"] || false
  }`;

export const buildPreviewUrl = (qrData: QrCodeProps | QrCodeResponse, location: string) => {
  const isWifi = qrData?.type === "Wifi";
  if (isWifi) return buildQRWifiUrl(qrData);
  else return `${location}/qr/${qrData?.reference}`;
};

export const getEmptyDays = (): BusinessHoursDays => {
  return {
    monday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    tuesday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    wednesday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    thursday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    friday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    saturday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
    sunday: {
      enabled: false,
      times: [
        {
          start: "",
          end: "",
        },
      ],
    },
  };
};

export const isFileAblob = (file) => {
  const FILTER_PREFIX = "blob:";

  return startsWith(file, FILTER_PREFIX);
};

const omittedLinks = (data: any) => {
  return data?.links?.length
    ? flatten(
        map(Array.from(Array(10).keys()), (el) => {
          return data.links &&
            isFileAblob(data?.links[el]?.linkImageUrl && data?.links[el]?.linkImageUrl[0])
            ? [`data.text-link-${el}`, `data.url-link-${el}`, `data.links[${el}].linkImageUrl[0]`]
            : [`data.text-link-${el}`, `data.url-link-${el}`];
        })
      )
    : [];
};

export const omittedValues = (data: any) => {
  return isFileAblob(data?.logoUrl)
    ? [
        ...map(socials, (social) => `data.${social.id}`),
        "data.logoUrl",
        "data.file",
        "qrStyle.logoUrl",
        ...omittedLinks(data),
      ]
    : [...map(socials, (social) => `data.${social.id}`), "data.file", ...omittedLinks(data)];
};

export const generateQrDataClone = (
  qrEditorContext: QREditorContextProps<any>,
  qrType: string,
  name?: string
): QRProps<any> => {
  const newQRData = clone(qrEditorContext?.qrData);

  if (newQRData) {
    newQRData.type = qrType as any;
    newQRData.data = {
      ...INITIAL_QR_DATA.data,
      typeName: QREditorAPItoDataConversor.QRTypeTransform(qrType) || name,
    };
    newQRData.qrStyle = { ...INITIAL_QR_DATA.qrStyle, logoUrl: "" };
    newQRData.isValidForm = false;
    newQRData.qrCodeId = undefined;
    newQRData.qrCodeReference = undefined;
  }

  return newQRData;
};

export const setNewQRData = (qrEditorContext, type, name?) => {
  const newQRData = generateQrDataClone(qrEditorContext, type, name);

  qrEditorContext?.setQrData(newQRData);
};

export const strValid = (str: string, punctuation?: string): string =>
  str ? `${str}${punctuation ?? ""} ` : "";

export const getFullAddress = (address, numeration, city, zipCode, state, country) => {
  const zipCodeStr = zipCode ? `Zip Code: ${zipCode} - ` : "";
  return (
    strValid(address, ",") +
    strValid(numeration, ",") +
    strValid(city, ",") +
    zipCodeStr +
    strValid(state, ",") +
    strValid(country)
  );
};
